<template>
  <section id="custom-chart" class="custom-chart">
    <!-- nps value -->
    <div class="nps-value" v-if="chart.npsValue && chart.total > 0">
      <span>
        {{ lang.surveyCharts.main.metric.npsValue[languageSelected] }}
      </span>
      <h3>{{ chart.npsValue }}%</h3>
    </div>

    <!-- apex charts -->
    <vue-apex-charts
      v-if="isApexChart(chart.chartInfo.type) && chart.total > 0"
      :key="key"
      :type="chart.chartInfo.type"
      :options="chart.chartOptions"
      :series="chart.series"
      height="340"
    />

    <!-- cluster charts -->
    <CentersTable
      v-if="isClusterChart(chart.chartInfo.type) && chart.chartData.centers"
      :cluster-centers="chart.chartData.centers"
      :show-similar="false"
    />

    <div v-if="emptyData(chart)" class="empty-data">
      <feather-icon icon="FrownIcon" class="w-8 h-8" />
      {{ lang.surveyCharts.main.messages.emptyData[languageSelected] }}
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { APEX_CHARTS } from '@/constants/constants'

export default {
  name: 'CustomChart',
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      key: new Date().getTime()
    }
  },
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
    CentersTable: () =>
      import('../../training/clustering/centers/CentersTable.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    isApexChart(type) {
      return APEX_CHARTS.includes(type)
    },
    isClusterChart(type) {
      return type === 'cluster'
    },
    emptyData(chart) {
      return (
        (this.isApexChart(chart.chartInfo.type) && chart.total === 0) ||
        (this.isClusterChart(chart.chartInfo.type) &&
          chart.chartData.centers.length === 0)
      )
    }
  }
}
</script>

<style lang="scss">
.custom-chart {
  display: flex;
  flex-direction: column;
  position: relative;

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
  }

  .nps-value {
    position: absolute;
    top: 0;
    right: 0;

    padding: 10px;
    border: solid 1px lightgray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
